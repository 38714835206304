import { withTheme } from 'emotion-theming'
import React from 'react'

import GlobalCss from '../../../../../gatsby-theme-att/src/components/CustomStyles/GlobalCss.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
  </>
))

export default GlobalStyles
