import { ThemeProvider } from '@leshen/ui'
import React from 'react'
import siteTheme from '../../theme'
import GlobalStyles from './CustomStyles'

const Wrapper = ({ children }) => (
  <ThemeProvider theme={siteTheme}>
    <GlobalStyles theme={siteTheme} />
    {children}
  </ThemeProvider>
)

Wrapper.defaultProps = {
  theme: {},
}

export default Wrapper
